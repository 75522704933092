@-webkit-keyframes header_image {
  0% {
    -webkit-transform: rotate(0.01deg) scale(1.1);
            transform: rotate(0.01deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: rotate(0.01deg) scale(1.1);
            transform: rotate(0.01deg) scale(1.1);
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
  }
}
.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::selection {
  background: #a0d93b;
  color: #fff;
}
.yellow ::selection {
  background: #d1a500;
  color: #000;
}
.orange ::selection {
  background: #f7a465;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(134, 188, 37, 0.1);
  box-sizing: border-box;
}
@media (min-width: 61.875rem) {
  * {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #000;
  font-family: "Lora", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.8;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6,
.style_h1,
.style_h2,
.style_h3,
.style_h4,
.style_h5,
.style_h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.style_h1 {
  letter-spacing: calc((1.375rem + 1.75 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.375rem;
  color: #4E4E4E;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1,
  .style_h1 {
    font-size: calc(1.375rem + 1.75 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1,
  .style_h1 {
    font-size: 3.125rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.25rem + 1.4375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.25rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding-top: 0.9em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.25rem + 1.4375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2,
  .style_h2 {
    font-size: 2.6875rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.125rem + 0.875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.125rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding-top: 0.9em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.125rem + 0.875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3,
  .style_h3 {
    font-size: 2rem;
  }
}
h4,
.style_h4 {
  letter-spacing: calc((1.125rem + 0.375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.125rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
}
@media (min-width: 20rem) {
  h4,
  .style_h4 {
    font-size: calc(1.125rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4,
  .style_h4 {
    font-size: 1.5rem;
  }
}
h5,
.style_h5 {
  letter-spacing: calc((1rem + 0.25 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5,
  .style_h5 {
    font-size: calc(1rem + 0.25 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5,
  .style_h5 {
    font-size: 1.25rem;
  }
}
h6,
.style_h6 {
  letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h6,
  .style_h6 {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h6,
  .style_h6 {
    font-size: 1.125rem;
  }
}
p {
  margin: 0 0 0.9em;
}
a {
  color: #86BC25;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #000;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(134, 188, 37, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"] {
  display: block;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #000;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #86BC25;
  display: inline-block;
  margin-bottom: 0.45em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #000;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(134, 188, 37, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.9em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.8em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.8em;
}
table {
  border-bottom: 0.125rem solid #808080;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #808080;
  padding: 0.6em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #999999;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.9em;
  padding: 0;
}
ol > li {
  padding-left: 1.8em;
  position: relative;
}
ol > li:before {
  color: #86BC25;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.8;
  position: absolute;
  text-align: right;
  width: 1.35em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg") !important;
  margin: 0 0 0.9em;
  padding-left: 1.35em;
}
ul > li {
  padding-left: 0.45em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.125rem solid #808080;
  margin: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.125rem solid #808080;
  font-weight: 700;
  padding: 0.9em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.9em 0.9em 0.9em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.125rem solid #808080;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.9em 0.6em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none !important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.8em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.8em;
}
figure.floatright {
  float: right;
  margin-left: 1.8em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.45em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((0.9375rem + 0.1875 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 0.9375rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(0.9375rem + 0.1875 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.125rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #404040;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::-ms-input-placeholder {
  color: #404040;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #404040;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #86BC25;
}
.red .required_asterisk {
  color: #C63C17;
}
.orange .required_asterisk {
  color: #F58734;
}
.blue .required_asterisk {
  color: #06BEE1;
}
.green .required_asterisk {
  color: #86BC25;
}
.yellow .required_asterisk {
  color: #FFCA05;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #86BC25;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.45em;
  padding: 0 0.45em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.red .error_warning {
  background: #C63C17;
}
.orange .error_warning {
  background: #F58734;
}
.blue .error_warning {
  background: #06BEE1;
}
.yellow .error_warning {
  background: #FFCA05;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.25rem + 1.4375 * (100vw - 20rem) / (73.75)) / 1000 * 0);
  font-size: 1.25rem;
  color: #000;
  font-family: "Lora", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.9em;
  padding: 0.9em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.25rem + 1.4375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 2.6875rem;
  }
}
.green .legend {
  color: #86BC25;
}
.red .legend {
  color: #C63C17;
}
.orange .legend {
  color: #F58734;
}
.blue .legend {
  color: #06BEE1;
}
.yellow .legend {
  color: #FFCA05;
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.45em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.125rem solid #808080;
  border-radius: 0.125rem;
  color: #000;
  margin: 0 0 1.2em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #86BC25;
}
.orange .input[aria-invalid="true"],
.orange .textarea[aria-invalid="true"] {
  border-color: #F58734;
}
.red .input[aria-invalid="true"],
.red .textarea[aria-invalid="true"] {
  border-color: #C63C17;
}
.blue .input[aria-invalid="true"],
.blue .textarea[aria-invalid="true"] {
  border-color: #06BEE1;
}
.yellow .input[aria-invalid="true"],
.yellow .textarea[aria-invalid="true"] {
  border-color: #FFCA05;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #86BC25;
  color: #000;
}
.orange .input[aria-invalid="true"]:focus,
.orange .textarea[aria-invalid="true"]:focus {
  border-color: #F58734;
}
.red .input[aria-invalid="true"]:focus,
.red .textarea[aria-invalid="true"]:focus {
  border-color: #C63C17;
}
.blue .input[aria-invalid="true"]:focus,
.blue .textarea[aria-invalid="true"]:focus {
  border-color: #06BEE1;
}
.yellow .input[aria-invalid="true"]:focus,
.yellow .textarea[aria-invalid="true"]:focus {
  border-color: #FFCA05;
}
.input:focus,
.textarea:focus {
  border-color: #86BC25;
  color: #000;
}
.orange .input:focus,
.orange .textarea:focus {
  border-color: #F58734;
}
.red .input:focus,
.red .textarea:focus {
  border-color: #C63C17;
}
.blue .input:focus,
.blue .textarea:focus {
  border-color: #06BEE1;
}
.yellow .input:focus,
.yellow .textarea:focus {
  border-color: #FFCA05;
}
.input {
  padding: 0 0.9em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3.125rem;
  }
}
.textarea {
  line-height: 1.8;
  overflow-x: hidden;
  padding: 0.9em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.45em 0.9em 0.9em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #86BC25;
}
.orange .control input[aria-invalid="true"] ~ .indicator {
  border-color: #F58734;
}
.red .control input[aria-invalid="true"] ~ .indicator {
  border-color: #C63C17;
}
.blue .control input[aria-invalid="true"] ~ .indicator {
  border-color: #06BEE1;
}
.yellow .control input[aria-invalid="true"] ~ .indicator {
  border-color: #FFCA05;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #86BC25;
  color: #000;
}
.orange .control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #F58734;
}
.red .control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #C63C17;
}
.blue .control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #06BEE1;
}
.yellow .control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #FFCA05;
}
.control input:focus ~ .indicator {
  border-color: #86BC25;
  color: #000;
}
.orange .control input:focus ~ .indicator {
  border-color: #F58734;
}
.red .control input:focus ~ .indicator {
  border-color: #C63C17;
}
.blue .control input:focus ~ .indicator {
  border-color: #06BEE1;
}
.yellow .control input:focus ~ .indicator {
  border-color: #FFCA05;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.9em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.125rem solid #808080;
  border-radius: 0.125rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #000;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  align-content: center;
  background: #fff;
  border: 0.125rem solid #808080;
  border-radius: 0.125rem;
  color: #000;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  margin: 0 0 1.2em;
  padding: 0 0.9em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.9em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #86BC25;
}
.orange .select.error {
  border-color: #F58734;
}
.red .select.error {
  border-color: #C63C17;
}
.blue .select.error {
  border-color: #06BEE1;
}
.yellow .select.error {
  border-color: #FFCA05;
}
.select.error.focus {
  border-color: #86BC25;
  color: #000;
}
.orange .select.error.focus {
  border-color: #F58734;
}
.red .select.error.focus {
  border-color: #C63C17;
}
.blue .select.error.focus {
  border-color: #06BEE1;
}
.yellow .select.error.focus {
  border-color: #FFCA05;
}
.select.focus {
  border-color: #86BC25;
  color: #000;
}
.orange .select.focus {
  border-color: #F58734;
}
.red .select.focus {
  border-color: #C63C17;
}
.blue .select.focus {
  border-color: #06BEE1;
}
.yellow .select.focus {
  border-color: #FFCA05;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.5rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.875rem;
  }
}
select option {
  min-height: 2.5rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.875rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select:focus::-ms-value {
  background: none;
  color: #000;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(134, 188, 37, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(134, 188, 37, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(134, 188, 37, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(134, 188, 37, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes down_arrow {
  0% {
    top: 0;
  }
  70% {
    top: -0.625rem;
  }
  100% {
    top: 0;
  }
}
@keyframes down_arrow {
  0% {
    top: 0;
  }
  70% {
    top: -0.625rem;
  }
  100% {
    top: 0;
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: rotate(0.01deg) scale(1.25);
            transform: rotate(0.01deg) scale(1.25);
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
@-webkit-keyframes image_zoom {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes image_zoom {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.8em;
  }
  * + [role="tablist"] {
    margin-top: 1.8em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    background: #f2f2f2;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.45em;
    min-width: 100%;
    padding: 0.6em 0.9em;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #86BC25;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #86BC25;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #86BC25;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #86BC25;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"] a {
    color: #000 !important;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.8em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper a {
    color: #86BC25;
  }
  .orange .contact_wrapper a {
    color: #F58734;
  }
  .red .contact_wrapper a {
    color: #C63C17;
  }
  .blue .contact_wrapper a {
    color: #06BEE1;
  }
  .yellow .contact_wrapper a {
    color: #FFCA05;
  }
  .contact_wrapper p {
    margin-right: 2.7em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  .address_vcard_button {
    margin: 0.9em 0;
  }
  .address_call_button {
    margin: 0.9em 0;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: inline-block;
    fill: #aaa;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    width: 4.6875rem;
    z-index: 50;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #cfe4a8;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .orange .cookie_banner {
    background: #fbcfae;
  }
  .red .cookie_banner {
    background: #e8b1a2;
  }
  .blue .cookie_banner {
    background: #9be5f3;
  }
  .yellow .cookie_banner {
    background: #ffea9b;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.2em 0.9em 1.2em 1.8em;
  }
  .cookie_banner a:not(.button) {
    color: #000;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.9em;
    padding: 0 0.9em;
  }
  .cookie_banner .close > span {
    min-height: 2.75rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.36em;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
    height: 100%;
  }
  .dropdown_menu > ul li {
    position: relative;
  }
  .dropdown_menu > ul li:last-child > ul {
    right: 0;
  }
  .dropdown_menu > ul li:hover > a,
  .dropdown_menu > ul li.hover > a {
    background: #fff;
  }
  .dropdown_menu > ul li:hover > ul,
  .dropdown_menu > ul li.hover > ul {
    visibility: visible;
  }
  .dropdown_menu > ul li .icon {
    box-sizing: content-box;
    display: inline-block;
    height: 0.625rem;
    padding-left: 0.9em;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #000;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding-left: 0.72em;
    padding-right: 0.72em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    height: 100%;
  }
  .dropdown_menu > ul > li > a.active {
    background: #fff;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    box-shadow: 0 0 0.3125rem 0 rgba(0, 0, 0, 0.25);
    padding-bottom: 1.8em;
    padding-top: 1.8em;
    position: absolute;
    visibility: hidden;
    z-index: 1000;
    padding-left: 0.9em;
    padding-right: 0.9em;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #000;
    display: -webkit-box;
    display: flex;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding: 0.45em 0;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
  }
  .dropdown_menu > ul > li ul a:hover,
  .dropdown_menu > ul > li ul a.active {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul ul {
    left: 100%;
    position: absolute;
    top: -0.9em;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.9em;
  }
  .breadcrumbs p {
    margin: 0 0.45em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.45em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #86BC25;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.45em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #86BC25;
    height: auto;
    left: 0.45em;
    top: 0.45em;
    width: auto;
  }
  .google_maps {
    margin: 0 0 0.9em;
  }
  .google_maps .map {
    background: #999999;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.9em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #ffffff;
    border-color: #86BC25;
    color: #95d129;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #95d129;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.375rem rgba(134, 188, 37, 0.2);
    z-index: 15;
    background: #86BC25;
    border-color: #fff;
    color: #719e1f;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #719e1f;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #808080;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.6em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #000;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.125rem 0 0 0.125rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.125rem 0.125rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.125rem;
    color: #86BC25;
    font-size: 85%;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .google_maps .direction {
    margin: 0.9em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.9em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.125rem solid #808080;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.125rem solid #808080;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.9em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.9em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
    width: 100%;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  footer {
    margin-top: 0;
  }
  footer .footer_address .company_name {
    color: #86BC25;
    line-height: 1.2;
  }
  footer .footer_address .company_name .addition {
    display: block;
    font-size: 80%;
  }
  footer .footer_address .contact_wrapper {
    display: block;
  }
  footer .footer_address .contact_data {
    margin-bottom: 0;
    margin-top: 0.9em;
  }
  footer .footer_address .contact strong {
    min-width: auto;
  }
  footer .footer_address a:not(.button) {
    color: #000;
  }
  footer .footer_address .button {
    display: none;
  }
  footer .footer_bottom {
    background: #86BC25;
    margin-top: 3.6em;
  }
  footer .footer_bottom .container {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  footer .footer_bottom .company_logo {
    display: none;
  }
  footer .footer_bottom ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
  }
  footer .footer_bottom ul li:last-child a:after {
    display: none;
  }
  footer .footer_bottom a {
    color: #000;
    display: inline-block;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
  }
  footer .footer_bottom a:hover,
  footer .footer_bottom a.active {
    text-decoration: underline;
  }
  .aside {
    display: none;
  }
  .hero_image_with_content {
    padding-top: 1.8em;
  }
  .hero_image_with_content.orange h2 {
    color: #F58734;
  }
  .hero_image_with_content.orange .hero_content {
    border-bottom: 2.5rem solid #F58734;
    padding-bottom: 3.125rem;
  }
  .hero_image_with_content.blue h2 {
    color: #06BEE1;
  }
  .hero_image_with_content.green h2 {
    color: #86BC25;
  }
  .hero_image_with_content.red h2 {
    color: #C63C17;
  }
  .hero_image_with_content.red .hero_content {
    border-bottom: 2.5rem solid #C63C17;
    padding-bottom: 3.125rem;
  }
  .hero_image_with_content .hero_content {
    max-width: 58.125rem;
    padding: 0;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    position: relative;
    -webkit-transition: 1200ms;
    transition: 1200ms;
  }
  .hero_image_with_content .hero_image {
    display: none;
    position: relative;
  }
  .hero_image_with_content .hero_image .img_resize {
    background-position: 95% 0;
    background-size: cover;
    height: 100%;
    min-height: 43.75rem;
    -webkit-transition: 900ms;
    transition: 900ms;
    z-index: -1;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.9em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link a:hover .icon {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .infolist_top_link .icon {
    display: block;
    height: 3rem;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 3rem;
  }
  .disclaimer {
    background: #fff;
    border: 0.125rem solid #86BC25;
    border-radius: 1.25rem 0 1.25rem 0;
    color: #000;
    font-size: 85%;
    margin: 0.9em 0;
    padding: 1.2em 1.8em;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #fff;
    border: 0.125rem solid #86BC25;
    border-radius: 1.25rem 0 1.25rem 0;
    color: #000;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.8em;
    padding: 1.8em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.8em;
  }
  .lexikon_liste a {
    color: #000 !important;
  }
  .checklist_entry {
    border-top: 0.125rem solid #808080;
    margin-bottom: 0.9em;
    padding-top: 0.9em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .button {
    border-radius: 1.25rem 0 1.25rem 0;
    padding: 0 1.8em;
    -webkit-transition: 300ms;
    transition: 300ms;
    background: #86BC25;
    border: 0.125rem solid #86BC25;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .button .icon + .text {
    padding-left: 0.6em;
  }
  .button:focus {
    outline: 0;
    background: #86BC25;
    border-color: #fff;
    color: #719e1f;
  }
  .button:focus .icon {
    fill: #719e1f;
  }
  .button:hover {
    background: #fff;
    border-color: #86BC25;
    color: #719e1f;
    border-radius: 0 1.25rem 0 1.25rem;
  }
  .button:hover .icon {
    fill: #719e1f;
  }
  .button:active,
  .button.active {
    background: #ffffff;
    border: 0.125rem solid #86BC25;
    color: #95d129;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #95d129;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .button.bottom_spacing {
    margin-bottom: 0.9em;
  }
  .orange .button {
    background: #F58734;
    border: 0.125rem solid #F58734;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .orange .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .orange .button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .orange .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .orange .button .icon + .text {
    padding-left: 0.6em;
  }
  .orange .button:focus {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .orange .button:focus .icon {
    fill: #f37312;
  }
  .orange .button:hover {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .orange .button:hover .icon {
    fill: #f37312;
  }
  .orange .button:active,
  .orange .button.active {
    background: #f6954c;
    border: 0.125rem solid #f6954c;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .orange .button:active > span,
  .orange .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .orange .button:active .icon,
  .orange .button.active .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .orange .button:active .text,
  .orange .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .orange .button:active .icon + .text,
  .orange .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .red .button {
    background: #C63C17;
    border: 0.125rem solid #C63C17;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .red .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .red .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .red .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .red .button .icon + .text {
    padding-left: 0.6em;
  }
  .red .button:focus {
    background: #fff;
    border-color: #C63C17;
    color: #a63213;
  }
  .red .button:focus .icon {
    fill: #a63213;
  }
  .red .button:hover {
    background: #fff;
    border-color: #C63C17;
    color: #a63213;
  }
  .red .button:hover .icon {
    fill: #a63213;
  }
  .red .button:active,
  .red .button.active {
    background: #e64e25;
    border: 0.125rem solid #e64e25;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .red .button:active > span,
  .red .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .red .button:active .icon,
  .red .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .red .button:active .text,
  .red .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .red .button:active .icon + .text,
  .red .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .blue .button {
    background: #06BEE1;
    border: 0.125rem solid #06BEE1;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .blue .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .blue .button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .blue .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .blue .button .icon + .text {
    padding-left: 0.6em;
  }
  .blue .button:focus {
    background: #fff;
    border-color: #06BEE1;
    color: #05a1be;
  }
  .blue .button:focus .icon {
    fill: #05a1be;
  }
  .blue .button:hover {
    background: #fff;
    border-color: #06BEE1;
    color: #05a1be;
  }
  .blue .button:hover .icon {
    fill: #05a1be;
  }
  .blue .button:active,
  .blue .button.active {
    background: #08d2f8;
    border: 0.125rem solid #08d2f8;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .blue .button:active > span,
  .blue .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .blue .button:active .icon,
  .blue .button.active .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .blue .button:active .text,
  .blue .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .blue .button:active .icon + .text,
  .blue .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .yellow .button {
    background: #FFCA05;
    border: 0.125rem solid #FFCA05;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .yellow .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .yellow .button .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .yellow .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .yellow .button .icon + .text {
    padding-left: 0.6em;
  }
  .yellow .button:focus {
    background: #fff;
    border-color: #FFCA05;
    color: #e0b100;
  }
  .yellow .button:focus .icon {
    fill: #e0b100;
  }
  .yellow .button:hover {
    background: #fff;
    border-color: #FFCA05;
    color: #e0b100;
  }
  .yellow .button:hover .icon {
    fill: #e0b100;
  }
  .yellow .button:active,
  .yellow .button.active {
    background: #ffcf1f;
    border: 0.125rem solid #ffcf1f;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .yellow .button:active > span,
  .yellow .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .yellow .button:active .icon,
  .yellow .button.active .icon {
    fill: #000;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .yellow .button:active .text,
  .yellow .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .yellow .button:active .icon + .text,
  .yellow .button.active .icon + .text {
    padding-left: 0.6em;
  }
  .button_overview {
    display: -webkit-box;
    display: flex;
  }
  .button_overview .button {
    margin-right: 0.9em;
  }
  .form_disclaimer {
    background: #fff;
    border: 0.125rem solid #86BC25;
    border-radius: 1.25rem 0 1.25rem 0;
    color: #000;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .orange .form_disclaimer {
    border-color: #F58734;
  }
  .red .form_disclaimer {
    border-color: #C63C17;
  }
  .blue .form_disclaimer {
    border-color: #06BEE1;
  }
  .yellow .form_disclaimer {
    border-color: #FFCA05;
  }
  .form_disclaimer a {
    color: #000 !important;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1.2em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #86BC25;
  }
  .orange .file.error .file_name {
    border-color: #F58734;
  }
  .red .file.error .file_name {
    border-color: #C63C17;
  }
  .blue .file.error .file_name {
    border-color: #06BEE1;
  }
  .yellow .file.error .file_name {
    border-color: #FFCA05;
  }
  .file .file_name {
    background: #fff;
    border: 0.125rem solid #808080;
    border-radius: 0.125rem 0 0 0.125rem;
    color: #000;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.9em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.5rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0.125rem 0.125rem 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.8em;
    z-index: 10;
    background: #F58734;
    border: 0.125rem solid #F58734;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.6em;
  }
  .file .file_button:hover {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .file .file_button:hover .icon {
    fill: #f37312;
  }
  .file .file_button.focus {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .file .file_button.focus .icon {
    fill: #f37312;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #f37312;
  }
  .file [type="file"]:active ~ .file_button {
    background: #fff;
    border-color: #F58734;
    color: #f37312;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #f37312;
  }
  button {
    cursor: pointer;
    margin: 0.9em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.8em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.9em;
  }
  .job_overview {
    margin-bottom: 0.9em;
  }
  .page_header {
    background: #86BC25;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  .page_header .header_fixed {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .page_header .menu_search {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    height: 100%;
  }
  .company_logo {
    width: 16.5625rem;
    max-width: 16.5625rem;
    display: block;
    background: #fff;
    padding: 0.45em 0.9em;
    position: relative;
    flex-shrink: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: block !important;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 600ms;
    transition: height 600ms;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 112.5%;
    height: 2.75rem;
    margin-right: 2.7em;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.75rem;
    margin-right: 0.9em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.875rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #000;
    display: block;
    height: 0.125rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    background: rgba(0, 0, 0, 0);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #86BC25;
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.8em 0;
    position: absolute;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  .offcanvas_menu .offcanvas_menu_content > ul {
    padding-top: 1.8em;
    text-align: center;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.8em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #000;
    display: inline-block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 3.125rem;
    padding: 0 0.9em;
    text-decoration: none;
    background: #86BC25;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background: #fff;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #000;
    display: block;
    padding: 0.45em 0 0.45em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.125rem solid #808080;
    border-top: 0.125rem solid #808080;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #999999;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(153, 153, 153, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.8em 3.6em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.8em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.8em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.9em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.8em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #F58734;
    border: 0.125rem solid #F58734;
    border-radius: 0.125rem;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #fff;
    border: 0.125rem solid #F58734;
    box-shadow: 0 0 0 0.375rem rgba(245, 135, 52, 0.2);
    color: #F58734;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #fff;
    border: 0.125rem solid #F58734;
    color: #F58734;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 3rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.9em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    color: #000;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #fff;
    border: 0.125rem solid #86BC25;
    border-radius: 1.25rem 0 1.25rem 0;
    color: #000;
    margin-bottom: 0.9em;
    padding: 1.2em 1.8em;
  }
  .orange .open_street_map .form_disclaimer {
    border-color: #F58734;
  }
  .red .open_street_map .form_disclaimer {
    border-color: #C63C17;
  }
  .blue .open_street_map .form_disclaimer {
    border-color: #06BEE1;
  }
  .yellow .open_street_map .form_disclaimer {
    border-color: #FFCA05;
  }
  .open_street_map .form_disclaimer a {
    color: #000;
  }
  .opening_times .title {
    padding-top: 0.9em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.7em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #808080;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0.125rem 0.125rem 0;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    background: #86BC25 !important;
    border: 0.125rem solid #86BC25 !important;
    color: #fff !important;
    display: -webkit-inline-box !important;
    display: inline-flex !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
    text-decoration: none !important;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms !important;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    width: 2.75rem;
  }
  form[role="search"] button > span {
    -webkit-box-align: center !important;
            align-items: center !important;
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-pack: center !important;
            justify-content: center !important;
    text-align: center !important;
    min-height: 2.5rem !important;
  }
  form[role="search"] button .icon {
    fill: #fff !important;
    flex-shrink: 0 !important;
    height: 1.5rem !important;
    -webkit-transition: fill 300ms !important;
    transition: fill 300ms !important;
    width: 1.5rem !important;
  }
  form[role="search"] button .text {
    font-weight: 400 !important;
    padding: 0.45em 0 !important;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.6em !important;
  }
  form[role="search"] button[disabled] {
    background-color: #999999;
    border-color: #8c8c8c;
    color: #808080;
  }
  form[role="search"] button[disabled] .icon {
    fill: #000;
  }
  form[role="search"] button > span {
    width: 100%;
  }
  form[role="search"] button:focus {
    position: relative;
    z-index: 10;
    background: #86BC25;
    border-color: #fff;
    color: #719e1f;
  }
  form[role="search"] button:focus .icon {
    fill: #719e1f;
  }
  form[role="search"] button .icon {
    height: 2.5rem;
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.125rem solid #808080;
    border-radius: 0.125rem 0 0 0.125rem;
    color: #000;
    flex-basis: 100%;
    margin: 0;
    padding: 0 0.9em;
    -webkit-transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    transition: background 300ms, border-color 300ms, box-shadow 300ms, color 300ms, outline 300ms, z-index 300ms;
    height: 2.75rem;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #86BC25;
    color: #000;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.9em;
    padding-top: 0.45em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    width: 2.75rem;
  }
  .toggle_search_box label {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .toggle_search_box label:hover ~ a {
    fill: #fff;
  }
  .toggle_search_box a {
    fill: #000;
  }
  .toggle_search_box a .icon {
    display: block;
    height: 1.625rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.625rem;
  }
  .toggle_search_box .open_search_box {
    display: block;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.9em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    -webkit-box-align: center;
            align-items: center;
    background: #86BC25;
    border: 0.125rem solid #86BC25;
    border-radius: 0.125rem;
    fill: #fff;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1.8em;
    top: 1.8em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #86BC25;
    border-color: #fff;
    outline: 0;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover {
    background: #fff;
    border-color: #86BC25;
    color: #719e1f;
  }
  .search_box .close_search_box_wrapper .close_search_box:hover .icon {
    fill: #719e1f;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 1.5625rem solid #fff;
    border-radius: 0.125rem;
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    width: 2.75rem;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 115%;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: -webkit-box;
    display: flex;
  }
  .sitemap ul a {
    color: #000 !important;
  }
  .steuernews_preview {
    margin-bottom: 0.9em;
  }
  .steuernews_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.8em;
  }
  .steuernews_preview .teaser_text {
    color: #000;
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .title {
    word-wrap: break-word;
    color: #000 !important;
  }
  .news_entity .hide_image_author,
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: right;
    margin-left: 1.8em;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_issues a {
    margin-bottom: 0.45em;
    margin-right: 0.45em;
  }
  .steuernews_disclaimer {
    clear: both;
    border-color: #C63C17;
  }
  .steuernews_sidebar {
    margin: 1.8em 0 0.9em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.9em;
  }
  .steuernews_current_issue ul {
    margin-left: 1.25rem;
  }
  .steuernews_current_issue a:not(.button) {
    color: #000 !important;
  }
  .steuernews_current_issue a {
    display: inline-block;
    margin-right: 0.45em;
    margin-bottom: 0.45em;
    min-width: 11.25rem;
  }
  .steuernews_current_issue a.active {
    color: #C63C17 !important;
  }
  .header_background {
    background-position: center right;
    background-size: cover;
    margin: 0 auto;
  }
  main .header_background {
    min-height: 12.5rem;
    background-position: center center;
  }
  .blue h2,
  .blue h3 {
    color: #06BEE1;
  }
  .blue main ul {
    list-style: url("../images/list_style_image_rectangle_blue.svg") !important;
  }
  .blue main a:not(.button) {
    color: #06BEE1;
  }
  .green h2,
  .green h3 {
    color: #86BC25;
  }
  .green main ul {
    list-style: url("../images/list_style_image_rectangle.svg") !important;
  }
  .green main a:not(.button) {
    color: #86BC25;
  }
  .red h2,
  .red h3 {
    color: #C63C17;
  }
  .red main ul {
    list-style: url("../images/list_style_image_rectangle_red.svg") !important;
  }
  .red main a:not(.button) {
    color: #C63C17;
  }
  .orange h2,
  .orange h3 {
    color: #F58734;
  }
  .orange main ul {
    list-style: url("../images/list_style_image_rectangle_orange.svg") !important;
  }
  .orange main a:not(.button) {
    color: #F58734;
  }
  .yellow  h2,
  .yellow  h3 {
    color: #ebb900;
  }
  .yellow  main ul {
    list-style: url("../images/list_style_image_rectangle_yellow.svg") !important;
  }
  .yellow  main a:not(.button) {
    color: #FFCA05;
  }
  .image_wrapper {
    position: relative;
  }
  .image_wrapper .content_box_wrapper {
    padding-left: 0.9em;
    padding-right: 0.9em;
    align-content: center;
    background: rgba(255, 255, 255, 0.95);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    max-width: 56.875rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  .image_wrapper .content_box_wrapper.left {
    left: 0;
    right: inherit;
  }
  footer .image_wrapper .content_box_wrapper {
    bottom: 0;
    min-height: 18.75rem;
    top: 0;
    width: 100%;
    padding-left: 0.45em;
    padding-right: 0.45em;
  }
  .image_wrapper .content_box_wrapper .corners_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    width: 100%;
  }
  .image_wrapper .content_box_wrapper h1,
  .image_wrapper .content_box_wrapper .footer_address {
    position: relative;
  }
  .image_wrapper .content_box_wrapper h1:before,
  .image_wrapper .content_box_wrapper .footer_address:before,
  .image_wrapper .content_box_wrapper h1:after,
  .image_wrapper .content_box_wrapper .footer_address:after {
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .image_wrapper .content_box_wrapper h1:before,
  .image_wrapper .content_box_wrapper .footer_address:before {
    background-image: url("../images/corners_top.svg");
    background-position: top;
  }
  .image_wrapper .content_box_wrapper h1:after,
  .image_wrapper .content_box_wrapper .footer_address:after {
    background-image: url("../images/corners_bottom.svg");
    background-position: bottom;
  }
  .image_wrapper .content_box_wrapper h1 {
    font-size: 1.375rem;
    margin-bottom: 0;
    max-width: 35.3125rem;
    padding-bottom: 1.8em;
    padding-top: 1.8em;
    word-break: break-word;
  }
  body:not(.startpage) .image_wrapper .content_box_wrapper h1 {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin: 0 1.2em;
    min-height: 10rem;
    padding-bottom: 0.9em;
    padding-top: 0.9em;
    width: 100%;
  }
  .image_wrapper .content_box_wrapper h1:before,
  .image_wrapper .content_box_wrapper h1:after {
    left: -10%;
    width: 120%;
  }
  .image_wrapper .content_box_wrapper .footer_address {
    padding-bottom: 1.8em;
    padding-left: 1.8em;
    padding-right: 0;
    padding-top: 1.8em;
  }
  .image_wrapper .arrow_container {
    display: none;
  }
  .box_with_text_and_link {
    margin-top: 1.8em;
    padding-bottom: 1.8em;
    padding-left: 1.8em;
    padding-right: 1.8em;
    padding-top: 1.8em;
    position: relative;
  }
  .box_with_text_and_link:before,
  .box_with_text_and_link:after {
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .box_with_text_and_link:before {
    background-image: url("../images/orange_corners_top.svg");
    background-position: top;
  }
  .box_with_text_and_link:after {
    background-image: url("../images/orange_corners_bottom.svg");
    background-position: bottom;
  }
  .box_with_text_and_link .button_wrapper {
    margin: 1.8em auto 0;
    text-align: center;
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .box_with_text_and_link .button_wrapper .button {
    margin: 0.45em 0.45em;
  }
  .links_overview .button_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1.8em;
  }
  .links_overview .button {
    margin: 0.45em 0;
    min-width: 18.125rem;
  }
  .content_right_side {
    background: rgba(255, 255, 255, 0.98);
    padding: 0.9em 1.8em;
    padding-left: 1.8em;
  }
  .steuernews_contact_person {
    background: #999999;
    border-radius: 0.125rem;
    margin-bottom: 1.8em;
    padding: 1.8em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.8em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0.125rem;
    color: #86BC25;
    display: block;
    line-height: 1.8;
    margin: 1em 0;
    padding: 0.9em;
    text-align: center;
  }
  .steuernews_navigation {
    margin: 1.8em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav li:first-child a {
    padding-left: 0;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.8;
    padding: 0 1.8em;
  }
  .steuernews_newsletter_subscribe {
    background: #999999;
    border-radius: 0.125rem;
    margin-bottom: 1.8em;
    padding: 1.8em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_video_overview {
    margin-bottom: 1.8em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.9em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #86BC25;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .team_overview {
    margin-top: 1.8em;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.9em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    display: -webkit-box;
    display: flex;
    margin: 0 1.8em 0.9em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.9em;
  }
  .team_entry .photo {
    margin-bottom: 0.9em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.9em;
  }
  .testimonial_overview .testimonial {
    display: -webkit-box;
    display: flex;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #86BC25;
    border: 0.125rem solid #86BC25;
    border-radius: 1.25rem 0 1.25rem;
    bottom: 100%;
    color: #000;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.8;
    padding: 0.9em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  .orange [role="tooltip"] {
    background: #F58734;
    border-color: #F58734;
  }
  .red [role="tooltip"] {
    background: #C63C17;
    border-color: #C63C17;
  }
  .blue [role="tooltip"] {
    background: #06BEE1;
    border-color: #06BEE1;
  }
  .yellow [role="tooltip"] {
    background: #FFCA05;
    border-color: #FFCA05;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #86BC25;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  .orange [role="tooltip"]:after {
    border-top-color: #F58734;
  }
  .red [role="tooltip"]:after {
    border-top-color: #C63C17;
  }
  .blue [role="tooltip"]:after {
    border-top-color: #06BEE1;
  }
  .yellow [role="tooltip"]:after {
    border-top-color: #FFCA05;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #86BC25;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  .orange [role="tooltip"]:before {
    border-top-color: #F58734;
  }
  .red [role="tooltip"]:before {
    border-top-color: #C63C17;
  }
  .blue [role="tooltip"]:before {
    border-top-color: #06BEE1;
  }
  .yellow [role="tooltip"]:before {
    border-top-color: #FFCA05;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0.9em 0;
  }
  .video_overview .image img,
  .video_infolists_overview .image img,
  .video_infolist_overview .image img,
  .video_tutorial_overview .image img,
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #86BC25;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.8em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.9em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.8em;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.8em;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #86BC25;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #86BC25;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .download_overview {
    margin-bottom: 0.9em;
  }
  .download_overview .touch_link {
    margin-bottom: 0.9em;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0;
    padding-top: 0;
  }
  .download_overview .touch_link .title,
  .download_overview .touch_link .text {
    color: #000;
  }
  .steuernews_article_slider {
    display: -webkit-box;
    display: flex;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .steuernews_article_slider .slider {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    left: 0;
    position: absolute;
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    position: relative;
  }
  .steuernews_article_slider .slide .image {
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    max-width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    padding: 0.9em 0;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
    word-wrap: break-word;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.9em;
  }
  .steuernews_article_slider .control_slides {
    -webkit-box-align: center;
            align-items: center;
    background: #999999;
    display: -webkit-box;
    display: flex;
    fill: #000;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 30%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: none;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides.show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #86BC25;
    outline: 0;
  }
  .steuernews_article_slider .control_slides .icon {
    height: 1.75rem;
    width: 1.75rem;
  }
  .steuernews_article_slider .prev_slide {
    left: -0.0625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: -0.0625rem;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.9em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.9em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.45em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.8em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #86BC25;
    border-radius: 0.125rem 0.125rem 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.51428571em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
}
@media screen and (min-width: 35rem) {
  .cookie_banner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 65.625rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
  }
}
@media screen and (min-width: 70.625rem) {
  .dropdown_menu > ul > li > a {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media screen and (min-width: 81.25rem) {
  .dropdown_menu > ul > li > a {
    padding-left: 1.8em;
    padding-right: 1.8em;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li ul {
    padding-left: calc(0.9em + 0.9 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li ul {
    padding-left: 1.8em;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li ul {
    padding-right: calc(0.9em + 0.9 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li ul {
    padding-right: 1.8em;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2) * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  footer {
    margin-top: 3.6em;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .footer_address .contact_wrapper {
    line-height: 2;
  }
}
@media screen and (min-width: 20rem) {
  footer .footer_address .contact_data {
    margin-top: calc(0.9em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .footer_address .contact_data {
    margin-top: 2.7em;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .footer_address .button {
    min-width: 18.75rem;
    background: #86BC25;
    border: 0.125rem solid #86BC25;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  footer .footer_address .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  footer .footer_address .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  footer .footer_address .button .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  footer .footer_address .button .icon + .text {
    padding-left: 0.6em;
  }
  footer .footer_address .button.first {
    margin-top: 1.8em;
  }
  footer .footer_address .button:focus {
    background: #86BC25;
    border-color: #fff;
    color: #719e1f;
  }
  footer .footer_address .button:focus .icon {
    fill: #719e1f;
  }
  footer .footer_address .button:hover {
    background: #fff;
    border-color: #86BC25;
    color: #719e1f;
    border-radius: 0 1.25rem 0 1.25rem;
  }
  footer .footer_address .button:hover .icon {
    fill: #719e1f;
  }
  footer .footer_address .button:active,
  footer .footer_address .button.active {
    background: #ffffff;
    border: 0.125rem solid #86BC25;
    color: #95d129;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    transition: background 300ms, border 300ms, color 300ms, border-radius 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  footer .footer_address .button:active > span,
  footer .footer_address .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    min-height: 2.5rem;
  }
  footer .footer_address .button:active .icon,
  footer .footer_address .button.active .icon {
    fill: #95d129;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  footer .footer_address .button:active .text,
  footer .footer_address .button.active .text {
    font-weight: 400;
    padding: 0.45em 0;
  }
  footer .footer_address .button:active .icon + .text,
  footer .footer_address .button.active .icon + .text {
    padding-left: 0.6em;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  footer .footer_address .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  footer .footer_address .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  footer .footer_address .button:active > span,
  footer .footer_address .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  footer .footer_address .button:active > span,
  footer .footer_address .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .footer_bottom .container {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .footer_bottom .company_logo {
    display: inline-block;
    width: 15rem;
    max-width: 15rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  footer .footer_bottom .company_logo {
    width: calc(15rem + 5.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  footer .footer_bottom .company_logo {
    width: 20.625rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  footer .footer_bottom .company_logo {
    max-width: calc(15rem + 5.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  footer .footer_bottom .company_logo {
    max-width: 20.625rem;
  }
}
@media screen and (min-width: 35rem) {
  footer .footer_bottom ul {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (max-width: 38.125rem) {
  footer .footer_bottom ul {
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (max-width: 61.8125rem) {
  footer .footer_bottom ul {
    padding: 0.9em 0;
  }
}
@media screen and (min-width: 35rem) {
  footer .footer_bottom a:after {
    content: "|";
    height: 100%;
    margin: 0 0.9em;
  }
}
@media screen and (min-width: 61.875rem) {
  .aside {
    display: block;
    opacity: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    -webkit-transform: translateX(-100%) translateY(15%) rotate(-25deg);
            transform: translateX(-100%) translateY(15%) rotate(-25deg);
    -webkit-transition: 900ms;
    transition: 900ms;
    width: 200%;
    z-index: -1;
  }
  .aside.animated {
    -webkit-transform: translateX(-15%) translateY(15%) rotate(0);
            transform: translateX(-15%) translateY(15%) rotate(0);
    opacity: 1;
  }
}
@media screen and (min-width: 47.5rem) {
  .hero_image_with_content {
    padding-top: 0.9em;
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content {
    padding-top: 1.8em;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content.first {
    margin-top: 1.8em;
  }
}
@media screen and (min-width: 75rem) {
  .hero_image_with_content.first {
    margin-top: 3.6em;
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content.first {
    margin-top: 5.4em;
  }
}
@media screen and (min-width: 20rem) {
  .hero_image_with_content.orange .hero_content {
    padding-bottom: calc(3.125rem + 3.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content.orange .hero_content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (max-width: 61.8125rem) {
  .hero_image_with_content.blue .hero_content {
    border-bottom: 2.5rem solid #06BEE1;
    padding-bottom: 3.125rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .hero_image_with_content.blue .hero_content {
    padding-bottom: calc(3.125rem + 3.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .hero_image_with_content.blue .hero_content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content.blue .hero_image {
    border-bottom: 2.5rem solid #06BEE1;
  }
}
@media screen and (max-width: 61.8125rem) {
  .hero_image_with_content.green .hero_content {
    border-bottom: 2.5rem solid #86BC25;
    padding-bottom: 3.125rem;
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 20rem) {
  .hero_image_with_content.green .hero_content {
    padding-bottom: calc(3.125rem + 3.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (max-width: 61.8125rem) and (min-width: 93.75rem) {
  .hero_image_with_content.green .hero_content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content.green .hero_image {
    border-bottom: 2.5rem solid #86BC25;
  }
}
@media screen and (min-width: 20rem) {
  .hero_image_with_content.red .hero_content {
    padding-bottom: calc(3.125rem + 3.125 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content.red .hero_content {
    padding-bottom: 6.25rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content.animated .hero_image .img_resize {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    opacity: 1;
  }
  .hero_image_with_content.animated .hero_content {
    left: 0;
    opacity: 1;
  }
}
@media screen and (min-width: 20rem) {
  .hero_image_with_content .hero_content {
    padding-left: calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content .hero_content {
    padding-left: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .hero_image_with_content .hero_content {
    padding-right: calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content .hero_content {
    padding-right: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content .hero_content {
    left: 0;
    opacity: 0;
  }
}
@media screen and (min-width: 93.75rem) {
  .hero_image_with_content .hero_content {
    padding: 0 12.5rem 0 3.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .hero_image_with_content .hero_image {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .hero_image_with_content .hero_image .img_resize {
    background-position: top right;
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-25deg);
            transform: translateX(-100%) rotate(-25deg);
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.6em;
            column-gap: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  @supports (padding-left: env(safe-area-inset-left)) {
    html.iphone .container {
      padding-left: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875 * (100vw - 20rem) / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .orange .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .orange .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .orange .button:active > span,
  .orange .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .orange .button:active > span,
  .orange .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .red .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .red .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .red .button:active > span,
  .red .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .red .button:active > span,
  .red .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .blue .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blue .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .blue .button:active > span,
  .blue .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .blue .button:active > span,
  .blue .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .yellow .button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .yellow .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .yellow .button:active > span,
  .yellow .button.active > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .yellow .button:active > span,
  .yellow .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 35rem) {
  .page_header .header_fixed {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    max-height: 5rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .page_header .menu_search {
    height: 5rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .page_header .menu_search {
    width: 100%;
    -webkit-box-pack: justify;
            justify-content: space-between;
    height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    width: calc(16.5625rem + 5.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    width: 22.1875rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    max-width: calc(16.5625rem + 5.625 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 22.1875rem;
  }
}
@media screen and (min-width: 65.625rem) {
  .company_logo {
    margin-right: 0.625rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .company_logo:before,
  .company_logo:after {
    background: #fff;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .company_logo:before {
    left: 100%;
  }
  .company_logo:after {
    right: 100%;
  }
}
@media screen and (min-width: 65.625rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 65.5625rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:checked ~ div .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ div .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .open_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .close_offcanvas_menu,
  .offcanvas_menu_state:checked ~ div .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ div .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.8em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.8em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button > span {
    min-height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button {
    width: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button {
    width: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  form[role="search"] button .icon {
    height: calc(2.5rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  form[role="search"] button .icon {
    height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  input[type="search"] {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  input[type="search"] {
    height: 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_search_box {
    width: 4rem;
  }
}
@media screen and (min-width: 20rem) {
  .search_box form[role="search"] button {
    width: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .search_box form[role="search"] button {
    width: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .entry {
    margin-bottom: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_preview .entry {
    margin-bottom: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_current_issue a {
    min-width: calc(11.25rem + 2.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_current_issue a {
    min-width: 13.75rem;
  }
}
@media screen and (min-width: 35rem) {
  main .header_background {
    min-height: 25rem;
  }
}
@media screen and (min-width: 35rem) {
  main .header_background {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
}
@media screen and (min-width: 61.875rem) {
  main .header_background {
    background-position: center center;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (min-width: 20rem) {
  .image_wrapper .content_box_wrapper {
    padding-left: calc(0.9em + 4.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper {
    padding-left: 5.4em;
  }
}
@media screen and (min-width: 20rem) {
  .image_wrapper .content_box_wrapper {
    padding-right: calc(0.9em + 4.5 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper {
    padding-right: 5.4em;
  }
}
@media screen and (min-width: 35rem) {
  .image_wrapper .content_box_wrapper {
    width: 75%;
  }
}
@media screen and (min-width: 47.5rem) {
  .image_wrapper .content_box_wrapper {
    width: 65%;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .content_box_wrapper {
    width: 56%;
  }
}
@media screen and (max-width: 34.9375rem) {
  main .image_wrapper .content_box_wrapper {
    margin-top: -20%;
    padding-bottom: 0.9em;
    padding-top: 0.9em;
    position: relative;
  }
  .startpage main .image_wrapper .content_box_wrapper {
    padding-bottom: 1.8em;
    padding-top: 1.8em;
  }
}
@media screen and (min-width: 20rem) {
  footer .image_wrapper .content_box_wrapper {
    padding-left: calc(0.45em + 4.95 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .image_wrapper .content_box_wrapper {
    padding-left: 5.4em;
  }
}
@media screen and (min-width: 20rem) {
  footer .image_wrapper .content_box_wrapper {
    padding-right: calc(0.45em + 4.95 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  footer .image_wrapper .content_box_wrapper {
    padding-right: 5.4em;
  }
}
@media screen and (max-width: 47.4375rem) {
  footer .image_wrapper .content_box_wrapper {
    position: relative;
  }
  footer .image_wrapper .content_box_wrapper .corners_wrapper {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  footer .image_wrapper .content_box_wrapper .footer_address {
    margin: 0 auto;
  }
}
@media screen and (min-width: 47.5rem) {
  footer .image_wrapper .content_box_wrapper {
    width: 60%;
  }
  footer .image_wrapper .content_box_wrapper .corners_wrapper {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 61.875rem) {
  footer .image_wrapper .content_box_wrapper {
    width: 50%;
  }
}
@media screen and (min-width: 30rem) {
  body:not(.startpage) .image_wrapper .content_box_wrapper h1 {
    margin: 0 1.8em;
    padding-bottom: 1.8em;
    padding-top: 1.8em;
  }
}
@media screen and (min-width: 35rem) {
  body:not(.startpage) .image_wrapper .content_box_wrapper h1 {
    margin: 0 0.9em;
    min-height: 15.625rem;
  }
}
@media screen and (min-width: 93.75rem) {
  body:not(.startpage) .image_wrapper .content_box_wrapper h1 {
    min-width: 35.3125rem;
  }
}
@media screen and (min-width: 30rem) {
  .image_wrapper .content_box_wrapper h1 {
    font-size: 1.75rem;
  }
  .newspage .image_wrapper .content_box_wrapper h1 {
    font-size: 1.625rem;
  }
  .startpage .image_wrapper .content_box_wrapper h1 {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .content_box_wrapper h1 {
    font-size: 2.125rem;
  }
  .newspage .image_wrapper .content_box_wrapper h1 {
    font-size: 1.875rem;
  }
  .startpage .image_wrapper .content_box_wrapper h1 {
    font-size: 2.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .image_wrapper .content_box_wrapper h1 {
    font-size: 2.5rem;
  }
  .newspage .image_wrapper .content_box_wrapper h1 {
    font-size: 2.375rem;
  }
  .startpage .image_wrapper .content_box_wrapper h1 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper h1 {
    font-size: 2.75rem;
  }
  .newspage .image_wrapper .content_box_wrapper h1 {
    font-size: 2.625rem;
  }
  .startpage .image_wrapper .content_box_wrapper h1 {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 26.25rem) {
  .image_wrapper .content_box_wrapper h1:before,
  .image_wrapper .content_box_wrapper h1:after {
    left: -10%;
    width: 120%;
  }
}
@media screen and (min-width: 35rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-left: 1.8em;
    padding-right: 1.8em;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-left: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-left: 3.6em;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-right: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-right: 3.6em;
  }
}
@media screen and (min-width: 75rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-bottom: 3.6em;
    padding-top: 3.6em;
  }
}
@media screen and (min-width: 93.75rem) {
  .image_wrapper .content_box_wrapper .footer_address {
    padding-bottom: 5.4em;
    padding-top: 5.4em;
  }
}
@media screen and (min-width: 61.875rem) {
  .image_wrapper .arrow_container {
    right: 2.7em;
    bottom: 0.9em;
    display: block;
    position: absolute;
    z-index: 10;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  .image_wrapper .arrow_container {
    right: calc(2.7em + 8.100000000000001 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .image_wrapper .arrow_container {
    right: 10.8em;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 20rem) {
  .image_wrapper .arrow_container {
    bottom: calc(0.9em + 1.8000000000000003 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .image_wrapper .arrow_container {
    bottom: 2.7em;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 61.875rem) {
  .image_wrapper .arrow_container .icon_down_arrow {
    -webkit-animation: down_arrow 900ms ease-in-out infinite;
            animation: down_arrow 900ms ease-in-out infinite;
    display: block;
    height: 3.125rem;
    margin: 0 auto;
    position: relative;
    width: 3.125rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 75rem) {
  .image_wrapper .arrow_container .icon_down_arrow {
    height: 4.375rem;
    width: 4.375rem;
  }
}
@media screen and (min-width: 61.875rem) and (min-width: 93.75rem) {
  .image_wrapper .arrow_container .icon_down_arrow {
    height: 6.25rem;
    width: 6.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .box_with_text_and_link {
    margin-top: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .box_with_text_and_link {
    margin-top: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .box_with_text_and_link {
    padding-bottom: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .box_with_text_and_link {
    padding-bottom: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .box_with_text_and_link {
    padding-left: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .box_with_text_and_link {
    padding-left: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .box_with_text_and_link {
    padding-right: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .box_with_text_and_link {
    padding-right: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .box_with_text_and_link {
    padding-top: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .box_with_text_and_link {
    padding-top: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .links_overview .button_wrapper {
    margin-top: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .links_overview .button_wrapper {
    margin-top: 3.6em;
  }
}
@media screen and (min-width: 20rem) {
  .content_right_side {
    padding-left: calc(1.8em + 0.9000000000000001 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .content_right_side {
    padding-left: 2.7em;
  }
}
@media screen and (min-width: 20rem) {
  .team_overview {
    margin-top: calc(1.8em + 1.8 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview {
    margin-top: 3.6em;
  }
}
@media screen and (min-width: 93.75rem) {
  .team_overview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .slide .description {
    padding: 0 5.4em 0 3.6em;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider .control_slides {
    top: 50%;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.375 * (100vw - 20rem) / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.125rem;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.9em;
    max-width: 30rem !important;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 5.3125rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.8em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.9em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.8em;
  }
  .qr_code_print {
    border-top: 0.125rem solid;
    margin: 1.8em 0 0;
    padding: 0.9em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
